import React, { createContext, useContext } from "react";
import { isMobile } from "../lib/utils";

interface AppContextProps {
  isMobile: boolean;
}

const AppContext = createContext<AppContextProps>({
  isMobile: false,
});

export const AppWrapper: React.FC = ({ children }) => {
  const isMobileTest = isMobile();
  let sharedState = {
    test: "hello",
    isMobile: isMobileTest,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
};

export function useAppContext() {
  return useContext(AppContext);
}
