export const isMobile = () => {
  let isMobile = false;

  if (typeof window !== "undefined") {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      isMobile = true;
    } else {
      // false for not mobile device
      isMobile = false;
    }
  }

  return isMobile;
};
